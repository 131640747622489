import { Button } from "@/components/ui/button";
import { formatPrice } from "@/lib/format";
import { useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { TrashIcon } from "lucide-react";
import React from "react";
import toast from "react-hot-toast";

interface ShoppingCartItemProps {
  item: {
    cartItemId: string;
    category: string;
    product: {
      id: string;
      imageUrl: string;
      price: number;
      title: string;
    };
  };
}

const ShoppingCartItem = ({ item }: ShoppingCartItemProps) => {
  const queryClient = useQueryClient();

  const handleRemoveCartItem = async () => {
    try {
      await axios.delete(
        `${process.env.NEXT_PUBLIC_API_BASE_URL}/student/cart/item/${item.cartItemId}`
      );
      queryClient.invalidateQueries({ queryKey: ["shopping-cart"] });
      toast.success("Item deleted");
    } catch (error) {
      console.log("Error removing item from cart", error);
    }
  };

  const { product } = item;

  return (
    <div className="flex items-center gap-4">
      <img
        src={product.imageUrl}
        width="100"
        height="100"
        alt={product.title}
        className="aspect-square rounded-lg object-cover"
      />
      <div className="grid gap-1 text-sm">
        <div className="font-medium">{product.title}</div>
        <div className="text-gray-500 capitalize">
          Category: {item.category}
        </div>
        <div>{product.price ? formatPrice(product.price) : ""}</div>
      </div>
      <Button variant="outline" size="icon" onClick={handleRemoveCartItem}>
        <TrashIcon className="h-4 w-4" />
        <span className="sr-only">Remove</span>
      </Button>
    </div>
  );
};

export default ShoppingCartItem;
