export const formatPrice = (price: number) => {
  return new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  }).format(price / 100);
};

export const formatDate = (date: Date, format: string) => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const map: { [key: string]: number | string } = {
    mm: date.getMonth() + 1,
    MMM: months[date.getMonth()],
    dd: date.getDate(),
    yy: date.getFullYear().toString(),
    yyyy: date.getFullYear(),
  };

  return format.replace(/MMM|mm|dd|yy|yyy/gi, (matched) =>
    map[matched].toString()
  );
};
