import { useQuery } from "@tanstack/react-query";
import axios from "axios";

type Item = {
  cartItemId: string;
  category: string;
  product: {
    id: string;
    imageUrl: string;
    price: number;
    title: string;
  };
};

const fetchShoppingCartItems = async (): Promise<Array<Item> | undefined> => {
  try {
    const response = await axios.get(
      `${process.env.NEXT_PUBLIC_API_BASE_URL}/student/cart`
    );

    const { data } = response;

    return data;
  } catch (error) {
    console.log("Error fetching shopping cart items", error);
  }
};

const useShoppingCart = () => {
  return useQuery({
    queryKey: ["shopping-cart"],
    queryFn: () => fetchShoppingCartItems(),
  });
};

export { fetchShoppingCartItems, useShoppingCart };
