import { Button } from "@/components/ui/button";
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@/components/ui/sheet";
import { useShoppingCart } from "@/hooks/use-shopping-cart";
import { ShoppingCartIcon } from "lucide-react";
import ShoppingCartItem from "./shopping-cart-item";
import { NotificationBadge } from "@/components/ui/notification-badge";
import { Badge } from "@/components/ui/badge";
import { cn } from "@/lib/utils";
import ShoppingCartCheckoutBtn from "./shopping-cart-checkout-btn";

const ShoppingCart = () => {
  const { data, isPending, isFetching } = useShoppingCart();
  const cartItemCount = data?.length;

  console.log("ShoppingCart", { data, isPending, isFetching });

  return (
    <Sheet>
      <SheetTrigger asChild>
        <Button variant="ghost" className="relative">
          <ShoppingCartIcon />
          <Badge
            className={cn(
              "absolute top-0 right-0 rounded-full pointer-events-none",
              typeof cartItemCount !== "undefined" &&
                ("" + cartItemCount).length === 0
                ? "translate-x-1 -translate-y-1 px-1.5 py-1.5"
                : "translate-x-1.5 -translate-y-1.5 px-2"
            )}
          >
            {"" + cartItemCount ? cartItemCount : "0"}
          </Badge>
        </Button>
      </SheetTrigger>
      <SheetContent>
        <SheetHeader>
          <SheetTitle>Cart</SheetTitle>
          <SheetDescription>
            Click checkout when you&apos;re ready.
          </SheetDescription>
        </SheetHeader>
        <div className="py-4">
          {isPending ? <div>Loading...</div> : null}
          {data && data.length > 0 ? (
            <div className="grid gap-4">
              {data?.map((item) => (
                <ShoppingCartItem key={item.cartItemId} item={item} />
              ))}
            </div>
          ) : (
            <div>Cart is empty</div>
          )}
        </div>
        <SheetFooter>
          <SheetClose asChild>
            <ShoppingCartCheckoutBtn
              type="submit"
              disabled={cartItemCount == 0 || undefined}
            />
            {/* <Button type="submit">Checkout</Button> */}
          </SheetClose>
        </SheetFooter>
      </SheetContent>
    </Sheet>
  );
};

export default ShoppingCart;
