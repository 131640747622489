"use client";

import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import { useAuth } from "@clerk/nextjs";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { Logo } from "./logo";
import MobileSidebar from "./mobile-sidebar";
import NavbarRoutes from "./navbar-routes";
import { UserNav } from "./user-nav";
import ShoppingCart from "./shopping-cart";

const studentRoutes = [
  {
    label: "Dashboard",
    href: "/student",
  },
  {
    label: "All Courses",
    href: "/student/all-courses",
  },
];

const teacherRoutes: {
  label: string;
  href: string;
}[] = [
  {
    label: "Teacher",
    href: "/teacher",
  },
  // {
  //   label: "Courses",
  //   href: "/teacher/courses",
  // },
];

const adminRoutes: {
  label: string;
  href: string;
}[] = [
  {
    label: "Admin",
    href: "/admin",
  },
  {
    label: "Students",
    href: "/admin/students",
  },
  {
    label: "Orders",
    href: "/admin/orders",
  },
  {
    label: "Subscriptions",
    href: "/admin/subscriptions",
  },
];

const Navbar = () => {
  const pathname = usePathname();
  const { userId } = useAuth();

  const isStudentPage =
    pathname.includes("/student") &&
    !pathname.includes("/admin") &&
    !pathname.includes("/teacher");
  const isTeacherPage = pathname.includes("/teacher");
  const isAdminPage = pathname.includes("/admin");

  const routes = isTeacherPage
    ? teacherRoutes
    : isStudentPage
    ? studentRoutes
    : isAdminPage
    ? adminRoutes
    : [];

  return (
    <div className="h-full bg-white shadow-sm border-b">
      <div
        className={cn(
          "p-4 px-6 h-16 flex items-center justify-between container mx-auto"
        )}
      >
        <Link href={userId ? "/student" : "/"} className="flex-none">
          <Logo />
        </Link>
        {pathname === "/" ? (
          userId ? (
            <Link href="/student">
              <Button>Dashboard</Button>
            </Link>
          ) : (
            <Link href="/sign-in">
              <Button>Login</Button>
            </Link>
          )
        ) : (
          <div className="flex items-center">
            <NavbarRoutes routes={routes} />
            <div className="hidden md:flex items-center justify-center">
              <UserNav /> 
              <ShoppingCart/>
            </div>
            <MobileSidebar routes={routes} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
