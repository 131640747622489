"use client";

import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { Button } from "@/components/ui/button";
import { formatPrice } from "@/lib/format";
import { cn } from "@/lib/utils";
import axios from "axios";
import { Loader2 } from "lucide-react";
import { useState } from "react";
import toast from "react-hot-toast";

interface ShoppingCartCheckoutBtnProps {
  className?: string;
  size?: "default" | "icon" | "lg" | "sm";
  disabled?: boolean;
  type?: "button" | "submit" | "reset";
}
const ShoppingCartCheckoutBtn = ({
  className,
  size,
  disabled,
  type,
}: ShoppingCartCheckoutBtnProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const onClick = async () => {
    try {
      setIsLoading(true);

      const response = await axios.post(
        `${process.env.NEXT_PUBLIC_API_BASE_URL}/student/checkout/cart`
      );

      window.location.assign(response.data.url);
    } catch (error) {
      toast.error("Something went wrong");
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>
        <Button
          type={type}
          size={size ? size : "default"}
          onClick={onClick}
          disabled={isLoading || disabled}
          className={cn("w-full md:w-auto", className)}
        >
          {isLoading ? (
            <p className="flex items-center gap-x-2 ">
              <Loader2 size={20} className="animate-spin" /> Loading
            </p>
          ) : (
            "Checkout Now"
          )}
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle className="flex items-center gap-x-2 ">
            <Loader2 size={20} className="animate-spin" /> Loading
          </AlertDialogTitle>
          <AlertDialogDescription>
            Please wait while we load your checkout
          </AlertDialogDescription>
        </AlertDialogHeader>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default ShoppingCartCheckoutBtn;
